import React from 'react'

const Books = () => {
  return (
    <div className='books'>
      <h1>Skönlitteratur</h1>
      <section>
        <div className='img-container'>
          <img src={require('../images/bortvald_omslag_fram.jpg')} alt="Bild på bildomslag till boken 'Bortvald som barn'." />
        </div>
        <div className='book-desc'>
          <h2>Bortvald som barn</h2>
          <p>”Karin har ständigt fått höra hur det varit för trångt hemma hos er. Hur tror du att det då kändes när lillasyster föddes. 
            Hon kan ha förstått det som att det då plötsligt fanns plats för lillasyster, en plats som hon kan ha uppfattat rätteligen borde ha varit menad för henne. /…/ 
            Kan du förstå att hon knappast kan ha uppfattat det som något annat än att hon ständigt blivit bortvald. 
            Sanna mina ord! Det här är allvar Frida! Den här känslan riskerar hon att få bära med sig hela livet.”</p>
          <p>Den lilla flickan Karin, lever i trygghet på gården med sin mormor och morfar. 
            Samtidigt finns bandet till föräldrarna och syskonen inne i samhället. 
            Frågan om och i så fall när hon ska flytta till familjen skapar en osäkerhet som skaver och tär på Karin. 
            Handlingen genomsyras av upplevelser och förväntningar kopplade till relationer och emotioner samt hur känslor av otrygghet, 
            svek och utsatthet kan resultera i bristande självkänsla och sviktande tillit.</p>
          <br />
          <p>Visto Förlag</p>
          <p>Utgiven: oktober 2023</p>
          <p><a href="https://www.vistoforlag.se/vasterasforfattare-debuterar-med-roman-om-en-flickas-utsatthet-i-1920-talets-sverige/">Pressrelease från Visto Förlag</a></p>
          <p><a href="https://www.smakprov.se/bok/kartonnage/bortvald-som-barn-9789180734226/">Provläs boken här</a></p>
          <div className='order'>
            <h3>Beställ boken:</h3>
            <div className='logo-img'><a href='https://www.vistoforlag.se/bok/bortvald-som-barn/' aria-label='Beställ boken via förlagets webshop.' target='_blank' rel="noreferrer"><img src={require('../images/visto.png')} alt="Beställ boken från förlagets webshop."/></a></div>
            <div className='logo-img'><a href='https://www.bokus.com/bok/9789180734226/bortvald-som-barn/' aria-label='Beställ boken via Bokus.' target='_blank' rel="noreferrer"><img src={require('../images/bokus.jpg')} alt="Beställ boken från Bokus."/></a></div>
            <div className='logo-img'><a href='https://www.adlibris.com/se/bok/bortvald-som-barn-9789180734226' aria-label='Beställ boken via Adlibris.' target='_blank' rel="noreferrer"><img src={require('../images/adlibris.png')} alt="Beställ boken från Adlibris." /></a></div>
            <div className='logo-img'><a href='https://www.akademibokhandeln.se/bok/bortvald-som-barn/9789180734226' aria-label='Beställ boken via Akademibokhandeln.' target='_blank' rel="noreferrer"><img src={require('../images/akademibokhandeln.png')} alt="Beställ boken från Adlibris." /></a></div>
          </div>
          <div className='order'>
            <h3>Lyssna på boken:</h3>
            <div className='logo-img'><a href='https://www.bookbeat.se/search?title=Bortvald+som+barn&page=1&query=Bortvald+som+barn' aria-label='Lyssna på boken på Bookbeat.' target='_blank' rel="noreferrer"><img src={require('../images/bookbeat.jpg')} alt="Lyssna på boken på Bookbeat."/></a></div>
            <div className='logo-img'><a href='https://www.storytel.com/se/books/bortvald-som-barn-2763341' aria-label='Lyssna på boken på Storytel.' target='_blank' rel="noreferrer"><img src={require('../images/storytel2.jpg')} alt="Lyssna på boken på Storytel."/></a></div>
            <div className='logo-img'><a href='https://nextory.com/se/search?q=Bortvald%20Som%20Barn' aria-label='Lyssna på boken på Nextory.' target='_blank' rel="noreferrer"><img src={require('../images/nextory.png')} alt="Lyssna på boken på Storytel."/></a></div>
          </div>
        </div>
      </section>
      <section className='bok2'>
        <div className='img-container'>
          <img src={require('../images/bok2.jpg')} alt="Bild på bildomslag till boken 'Sårbarhet och livskraft'." />
        </div>
        <div className='book-desc'>
          <h2>Sårbarhet och livskraft</h2>
          <p>”Det är en tidig lördagsmorgon i maj och Karin cyklar längs landsvägen in mot skolan. Tänk att det är en dryg vecka sen hon fyllde åtta år. 
            Att cykla är en känsla av att man nästan flyger. Det är frihet. Hon njuter av hur vinden tar tag i håret. Nu hör hon hur göken gal söderöver 
            någonstans nere vid ravinen. Hon ryser till. Södergök är Dödergök. Framför henne uppenbarar sig konturerna av den stora mörka skolbyggnaden. 
            Från landsvägen uppfattar Karin att skolan nästan påminner om ett spökslott.”</p>
          <p><strong>Sårbarhet och livskraft</strong> är en fristående fortsättning på berättelsen om den lilla flickan Karins uppväxt för närmare 
          hundra år sen, en uppväxt präglad av tillit och värme men även osäkerhet och en ständigt närvarande sårbarhet. I den här romanen måste Karin 
          bemästra övergången till skolans värld. Mötet med skolan innebär nya utmaningar som kännetecknas av utsatthet och marginalisering, men även 
          inslag av tillit och en spirande livskraft.</p>
          <br />
          <p>Visto Förlag</p>
          <p>Utgiven: oktober 2024</p>
          {/* <p><a href="https://www.vistoforlag.se/vasterasforfattare-debuterar-med-roman-om-en-flickas-utsatthet-i-1920-talets-sverige/">Pressrelease från Visto Förlag</a></p>
          <p><a href="https://www.smakprov.se/bok/kartonnage/bortvald-som-barn-9789180734226/">Provläs boken här</a></p> */}
          <div className='order'>
            <h3>Beställ boken:</h3>
            <div className='logo-img'><a href='https://www.vistoforlag.se/bok/sarbarhet-och-livskraft/' aria-label='Beställ boken via förlagets webshop.' target='_blank' rel="noreferrer"><img src={require('../images/visto.png')} alt="Beställ boken från förlagets webshop."/></a></div>
            <div className='logo-img'><a href='https://www.bokus.com/bok/9789180737975/sarbarhet-och-livskraft/' aria-label='Beställ boken via Bokus.' target='_blank' rel="noreferrer"><img src={require('../images/bokus.jpg')} alt="Beställ boken från Bokus."/></a></div>
            <div className='logo-img'><a href='https://www.adlibris.com/se/bok/sarbarhet-och-livskraft-9789180737975' aria-label='Beställ boken via Adlibris.' target='_blank' rel="noreferrer"><img src={require('../images/adlibris.png')} alt="Beställ boken från Adlibris." /></a></div>
            <div className='logo-img'><a href='https://www.akademibokhandeln.se/bok/sarbarhet-och-livskraft/9789180737975' aria-label='Beställ boken via Akademibokhandeln.' target='_blank' rel="noreferrer"><img src={require('../images/akademibokhandeln.png')} alt="Beställ boken från Adlibris." /></a></div>
          </div>
          {/* <div className='order'>
            <h3>Lyssna på boken:</h3>
            <div className='logo-img'><a href='https://www.bookbeat.se/search?title=Bortvald+som+barn&page=1&query=Bortvald+som+barn' aria-label='Lyssna på boken på Bookbeat.' target='_blank' rel="noreferrer"><img src={require('../images/bookbeat.jpg')} alt="Lyssna på boken på Bookbeat."/></a></div>
            <div className='logo-img'><a href='https://www.storytel.com/se/books/bortvald-som-barn-2763341' aria-label='Lyssna på boken på Storytel.' target='_blank' rel="noreferrer"><img src={require('../images/storytel2.jpg')} alt="Lyssna på boken på Storytel."/></a></div>
            <div className='logo-img'><a href='https://nextory.com/se/search?q=Bortvald%20Som%20Barn' aria-label='Lyssna på boken på Nextory.' target='_blank' rel="noreferrer"><img src={require('../images/nextory.png')} alt="Lyssna på boken på Storytel."/></a></div>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default Books