import React from 'react'
// import { Link } from 'react-router-dom'

const Upcoming = () => {
  return (
    <div className='upcoming'>
      <h1>Aktuellt</h1>
      <p>Böckerna "Bortvald som barn" och "Sårbarhet och livskraft" finns nu även att köpa fysiskt på följande ställen: 
        <ul>
          <li><strong>Affärstryck</strong>, Storgatan 53, 665 30 Kil, 0554-317 10</li>
          <li><strong>Café Mosaic</strong>, Smedjegatan 2, 722 13 Västerås, 021-290 00</li>
        </ul>
      </p>
      <p><strong>2024-11-12:</strong> Västerås arbetarförening, damklubben, författarbesök med bokpresentation, kl. 19, Västerås.</p>
      <p><strong>2025-01-21:</strong> Uppläsningskväll kl. 17.30-19 på Stadsbiblioteket, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p><strong>2025-02-18:</strong> Uppläsningskväll kl. 17.30-19 på Stadsbiblioteket, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p><strong>2025-03-18:</strong> Uppläsningskväll kl. 17.30-19 på Stadsbiblioteket, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p><strong>2025-04-??:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning). Datum meddelas senare.</p>
      <p><strong>2025-05-20:</strong> Uppläsningskväll kl. 17.30-19 på Stadsbiblioteket, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>


      <h2>Passerat:</h2>
      <p className='past'><strong>2024-11-12:</strong> Västerås arbetarförening, damklubben, författarbesök med bokpresentation, kl. 19, Västerås.</p>
      <p className='past'><strong>2024-11-09:</strong> Värmlands Bokfestival kl. 10-16, Nöjesfabriken, Karlstad (bokbord). <a href="https://varmlandsbokfestival.se/2024/10/14/anders-garpelin/">Mer information här.</a></p>
      <p className='past'><strong>2024-11-08:</strong> Värmlands Bokfestival kl. 10-17, Nöjesfabriken, Karlstad (scenframträdande kl. 11.00, bokbord). <a href="https://varmlandsbokfestival.se/2024/10/14/anders-garpelin/">Mer information här.</a></p>
      <p className='past'><strong>2024-11-06:</strong> Författarbesök med bokpresentation, kl. 17-19, Hörsalen, Stadsbiblioteket, Västerås. <a href="https://bibliotekivastmanland.se/detaljerade-handelser/?id=22128">Mer information här.</a></p>
      <p className='past'><strong>2024-10-16:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p className='past'><strong>2024-10-12:</strong> Västerås litteraturfestival kl. 10-15, Stadsbiblioteket, Västerås (bokbord). <a href="https://bibliotekivastmanland.se/vasteras-litteraturfestival/">Mer information här.</a></p>
      <p className='past'><strong>2024-09-24:</strong> Uppläsningskväll kl. 17-19 på Stadsbiblioteket, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p className='past'><strong>2024-03-19:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p className='past'><strong>2024-02-27:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      <p className='past'><strong>2023-12-19:</strong> Akademibokhandeln på Igor, Västerås, har författarbesök av Anders Garpelin som säljer och signerar sin bok Bortvald som barn</p>
      <p className='past'><strong>2023-12-14:</strong> Akademibokhandeln på Igor, Västerås, har författarbesök av Anders Garpelin som säljer och signerar sin bok Bortvald som barn</p>
      <p className='past'><strong>2023-12-07:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, scenen framför caféborden, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-11-18:</strong> Värmlands Bokfestival kl. 10-16, Nöjesfabriken, Karlstad (bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här</a></p>
      <p className='past'><strong>2023-11-17:</strong> Värmlands Bokfestival kl. 10-17, Nöjesfabriken, Karlstad (scenframträdande 12.30,bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här</a></p>
      <p className='past'><strong>2023-11-09:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-10-31:</strong> Författarbesök med bokpresentation, kl. 17-19, Hörsalen, Stadsbiblioteket, Västerås. <a href="https://bibliotekivastmanland.se/detaljerade-handelser/?id=15790">Mer information här</a></p>
      <p className='past'><strong>2023-10-14:</strong> Västerås litteraturfestival kl. 10-15, Stadsbiblioteket, Västerås (bokbord)</p>
      <p className='past'><strong>2023-09-26:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-05-16:</strong> Uppläsningskväll på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
    </div>
  )
}

export default Upcoming